<template>
  <div class="equipmentStatus">
    <div class="top">
      <div class="title">
        服务情况
      </div>
    </div>
    <div class="bottom">
      <div class="one">
        <div class="one_1" v-for="(item,index) in list1" :key="index">
          <div class="one1_1">
            <!--圆圈可转动-->
            <div class="one11_1"></div>
            <div class="one11_2"></div>
            <div class="one11_3">
              <div class="one_text">{{ item.num }}</div>
              <div class="one_text2" v-if="index<3">户</div>
              <div class="one_text2" v-if="index==3">人次</div>
            </div>
          </div>

          <div class="one_2">
            <div>{{ item.name }}</div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "equipmentStatus",
  data() {
    return {
      list1: [
        {
          name: '项目推广',
          num: '44',
        },
        {
          name: '服务咖企',
          num: '22',
        },
        {
          name: '合同签订',
          num: '23',
        },
        {
          name: '培训人次',
          num: '2271',
        }
      ],
      list:[
        {
          name: '开机',
          num: 22,
          percentage: 34.21,
        },
        {
          name: '关机',
          num: 2,
          percentage: 68.03,
        },
        {
          name: '故障',
          num: 1,
          percentage: 34.21,
        },
        {
          name: '检修',
          num: 1,
          percentage: 34.21,
        },
      ],
      customColor: [
        {color: '#49B312'},
        {color: '#0eb3d0'},
        {color: '#BD352C'},
        {color: '#F59A23'},
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.equipmentStatus {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-image: url("@/assets/image/04.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 5%;
      padding-bottom: 2%;
      font-size: 24px;
      line-height: 33px;
      color: rgba(255, 255, 255, 1);

    }


  }

  .bottom {
    width: 100%;
    height: 86%;
    display: flex;
    justify-content: center;
    align-content: center;

    .one {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;



      .one_1 {
        width: 45%;
        height: 48%;
        display: flex;
        flex-direction: column;

        .one1_1 {
          width: 100%;
          height: 110px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          color: #fff;

          .one11_1 {
            width: 50%;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 1;
            background-image: url("@/assets/image/05.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-top: 25%;
            //animation: circleRotate 2s infinite linear;
          }
          .one11_2 {
            width: 50%;
            height: 60%;
            display: flex;
            justify-content: center;
            //align-items: center;
            position: absolute;
            z-index: 2;

            background-image: url("@/assets/image/06.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-top: 0;


          }

          .one11_3 {
            width: 60%;
            height: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            z-index: 3;

            .one_text {
              font-size: 28px;
              line-height: 38px;
            }
          }
        }
        .one_2 {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: rgba(61, 230, 255, 1);
        }
      }
    }

    @keyframes circleRotate {
      0% {
        transform: rotateY(0deg);
      }
      /*50% {
        transform: rotateY(-90deg)
      }*/
      100% {
        transform: rotateY(360deg)
      }
    }

  }
}
</style>
<style scoped>
/*::v-deep .el-progress-bar__outer{
  background-color: #16d3d5 !important;
}*/
</style>

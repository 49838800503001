<template>
  <div class="workReport">
    <div class="top">
      <div class="title">
        示范企业成效
      </div>
    </div>
    <div class="bottom">
      <el-carousel indicator-position="none" height="25vh" style="width: 100%;" interval="5000">
        <el-carousel-item>
          <div class="row">
            <div class="row-1">
              <div class="row-2" v-for="(item,index) in list" :key="index">
                <div class="row-3" v-for="(a,i) in item" :key="i">
                  <div :class="index%2!=0?'item1':'item11'">{{a.name}}</div>
                  <div class="item2">
                    <el-image :src="a.type=='down'?require('@/assets/image/18.png'):require('@/assets/image/17.png')" fit="cover" style="width: 8px;height: 8px"/>
                  </div>
                  <div class="item3">{{a.num}}</div>
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="row2-1">
                <div class="row2-2">中咖咖啡</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="row">
            <div class="row-1">
              <div class="row-2" v-for="(item,index) in list1" :key="index">
                <div class="row-3" v-for="(a,i) in item" :key="i">
                  <div :class="index%2!=0?'item1':'item11'">{{a.name}}</div>
                  <div class="item2">
                    <el-image :src="a.type=='down'?require('@/assets/image/18.png'):require('@/assets/image/17.png')" fit="cover" style="width: 8px;height: 8px"/>
                  </div>
                  <div class="item3">{{a.num}}</div>
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="row2-1">
                <div class="row2-2">高晟庄园</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="row">
            <div class="row-1">
              <div class="row-2" v-for="(item,index) in list2" :key="index">
                <div class="row-3" v-for="(a,i) in item" :key="i">
                  <div :class="index%2!=0?'item1':'item11'">{{a.name}}</div>
                  <div class="item2">
                    <el-image :src="a.type=='down'?require('@/assets/image/18.png'):require('@/assets/image/17.png')" fit="cover" style="width: 8px;height: 8px"/>
                  </div>
                  <div class="item3">{{a.num}}</div>
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="row2-1">
                <div class="row2-2">禾木咖啡</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="row">
            <div class="row-1">
              <div class="row-2" v-for="(item,index) in list3" :key="index">
                <div class="row-3" v-for="(a,i) in item" :key="i">
                  <div :class="index%2!=0?'item1':'item11'">{{a.name}}</div>
                  <div class="item2">
                    <el-image :src="a.type=='down'?require('@/assets/image/18.png'):require('@/assets/image/17.png')" fit="cover" style="width: 8px;height: 8px"/>
                  </div>
                  <div class="item3">{{a.num}}</div>
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="row2-1">
                <div class="row2-2">一树咖啡</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="row">
            <div class="row-1">
              <div class="row-2" v-for="(item,index) in list4" :key="index">
                <div class="row-3" v-for="(a,i) in item" :key="i">
                  <div :class="index%2!=0?'item1':'item11'">{{a.name}}</div>
                  <div class="item2">
                    <el-image :src="a.type=='down'?require('@/assets/image/18.png'):require('@/assets/image/17.png')" fit="cover" style="width: 8px;height: 8px"/>
                  </div>
                  <div class="item3">{{a.num}}</div>
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="row2-1">
                <div class="row2-2">咖蒙实业</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="row">
            <div class="row-1">
              <div class="row-2" v-for="(item,index) in list5" :key="index">
                <div class="row-3" v-for="(a,i) in item" :key="i">
                  <div :class="index%2!=0?'item1':'item11'">{{a.name}}</div>
                  <div class="item2">
                    <el-image :src="a.type=='down'?require('@/assets/image/18.png'):require('@/assets/image/17.png')" fit="cover" style="width: 8px;height: 8px"/>
                  </div>
                  <div class="item3">{{a.num}}</div>
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="row2-1">
                <div class="row2-2">蕊溪咖啡</div>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
<!--      <div class="row" style="border: 1px solid red">
        <div class="row-1">
          <div class="row-2" v-for="(item,index) in list" :key="index">
            <div class="row-3" v-for="(a,i) in item" :key="i">
              <div :class="index%2!=0?'item1':'item11'">{{a.name}}</div>
              <div class="item2">
                <el-image :src="a.type=='down'?require('@/assets/image/18.png'):require('@/assets/image/17.png')" fit="cover" style="width: 8px;height: 8px"/>
              </div>
              <div class="item3">{{a.num}}</div>
            </div>
          </div>
        </div>
        <div class="row2">
          <div class="row2-1">
            <div class="row2-2">中咖咖啡</div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "leftThere",
  data() {
    return {
      list: [
        [
          {
            name: '生产执行效率',
            type: 'add',
            num: '12%'
          },
          {
            name: '计划达成率',
            type: 'add',
            num: '5%'
          },
          {
            name: '质检执行率',
            type: 'add',
            num: '30%'
          },
          {
            name: '产品合格率',
            type: 'add',
            num: '2%'
          },
        ],
        [
          {
            name: '设备管理效率',
            type: 'add',
            num: '20%'
          },
          {
            name: '库存周转率',
            type: 'add',
            num: '10%'
          },
          {
            name: '物料配送准时率',
            type: 'add',
            num: '10%'
          },
          {
            name: '制单效率',
            type: 'add',
            num: '8倍'
          },
        ],
        [
          {
            name: '生产平均周期缩短',
            type: 'down',
            num: '1-2天'
          },
          {
            name: '数据录入时间',
            type: 'down',
            num: '40%'
          },
          {
            name: '政工作管理成本',
            type: 'down',
            num: '10%'
          },
        ],


        [
          {
            name: '培训成本',
            type: 'down',
            num: '15%'
          },
          {
            name: '工资成本',
            type: 'down',
            num: '13%'
          },
        ],
      ],
      list1: [
        [
          {
            name: '生产执行效率',
            type: 'add',
            num: '10%'
          },
          {
            name: '计划达成率',
            type: 'add',
            num: '3%'
          },
          {
            name: '质检执行率',
            type: 'add',
            num: '--'
          },
          {
            name: '产品合格率',
            type: 'add',
            num: '--'
          },
        ],
        [
          {
            name: '设备管理效率',
            type: 'add',
            num: '15%'
          },
          {
            name: '库存周转率',
            type: 'add',
            num: '--'
          },
          {
            name: '物料配送准时率',
            type: 'add',
            num: '--'
          },
          {
            name: '制单效率',
            type: 'add',
            num: '8倍'
          },
        ],
        [
          {
            name: '生产平均周期缩短',
            type: 'down',
            num: '1-2天'
          },
          {
            name: '数据录入时间',
            type: 'down',
            num: '35%'
          },
          {
            name: '政工作管理成本',
            type: 'down',
            num: '5%'
          },
        ],


        [
          {
            name: '培训成本',
            type: 'down',
            num: '10%'
          },
          {
            name: '工资成本',
            type: 'down',
            num: '2%'
          },
        ],
      ],
      list2: [
        [
          {
            name: '生产执行效率',
            type: 'add',
            num: '8%'
          },
          {
            name: '计划达成率',
            type: 'add',
            num: '3%'
          },
          {
            name: '质检执行率',
            type: 'add',
            num: '--'
          },
          {
            name: '产品合格率',
            type: 'add',
            num: '--'
          },
        ],
        [
          {
            name: '设备管理效率',
            type: 'add',
            num: '10%'
          },
          {
            name: '库存周转率',
            type: 'add',
            num: '5%'
          },
          {
            name: '物料配送准时率',
            type: 'add',
            num: '--'
          },
          {
            name: '制单效率',
            type: 'add',
            num: '8倍'
          },
        ],
        [
          {
            name: '生产平均周期缩短',
            type: 'down',
            num: '1-2天'
          },
          {
            name: '数据录入时间',
            type: 'down',
            num: '20%'
          },
          {
            name: '政工作管理成本',
            type: 'down',
            num: '5%'
          },
        ],


        [
          {
            name: '培训成本',
            type: 'down',
            num: '5%'
          },
          {
            name: '工资成本',
            type: 'down',
            num: '--'
          },
        ],
      ],
      list3: [
        [
          {
            name: '生产执行效率',
            type: 'add',
            num: '5%'
          },
          {
            name: '计划达成率',
            type: 'add',
            num: '--'
          },
          {
            name: '质检执行率',
            type: 'add',
            num: '--'
          },
          {
            name: '产品合格率',
            type: 'add',
            num: '--'
          },
        ],
        [
          {
            name: '设备管理效率',
            type: 'add',
            num: '15%'
          },
          {
            name: '库存周转率',
            type: 'add',
            num: '--'
          },
          {
            name: '物料配送准时率',
            type: 'add',
            num: '--'
          },
          {
            name: '制单效率',
            type: 'add',
            num: '8倍'
          },
        ],
        [
          {
            name: '生产平均周期缩短',
            type: 'down',
            num: '1-2天'
          },
          {
            name: '数据录入时间',
            type: 'down',
            num: '20%'
          },
          {
            name: '政工作管理成本',
            type: 'down',
            num: '5%'
          },
        ],


        [
          {
            name: '培训成本',
            type: 'down',
            num: '5%'
          },
          {
            name: '工资成本',
            type: 'down',
            num: '--'
          },
        ],
      ],
      list4: [
        [
          {
            name: '生产执行效率',
            type: 'add',
            num: '5%'
          },
          {
            name: '计划达成率',
            type: 'add',
            num: '--'
          },
          {
            name: '质检执行率',
            type: 'add',
            num: '--'
          },
          {
            name: '产品合格率',
            type: 'add',
            num: '--'
          },
        ],
        [
          {
            name: '设备管理效率',
            type: 'add',
            num: '5%'
          },
          {
            name: '库存周转率',
            type: 'add',
            num: '5%'
          },
          {
            name: '物料配送准时率',
            type: 'add',
            num: '--'
          },
          {
            name: '制单效率',
            type: 'add',
            num: '8倍'
          },
        ],
        [
          {
            name: '生产平均周期缩短',
            type: 'down',
            num: '1-2天'
          },
          {
            name: '数据录入时间',
            type: 'down',
            num: '20%'
          },
          {
            name: '政工作管理成本',
            type: 'down',
            num: '5%'
          },
        ],


        [
          {
            name: '培训成本',
            type: 'down',
            num: '5%'
          },
          {
            name: '工资成本',
            type: 'down',
            num: '--'
          },
        ],
      ],
      list5: [
        [
          {
            name: '生产执行效率',
            type: 'add',
            num: '5%'
          },
          {
            name: '计划达成率',
            type: 'add',
            num: '--'
          },
          {
            name: '质检执行率',
            type: 'add',
            num: '--'
          },
          {
            name: '产品合格率',
            type: 'add',
            num: '--'
          },
        ],
        [
          {
            name: '设备管理效率',
            type: 'add',
            num: '5%'
          },
          {
            name: '库存周转率',
            type: 'add',
            num: '5%'
          },
          {
            name: '物料配送准时率',
            type: 'add',
            num: '--'
          },
          {
            name: '制单效率',
            type: 'add',
            num: '8倍'
          },
        ],
        [
          {
            name: '生产平均周期缩短',
            type: 'down',
            num: '1-2天'
          },
          {
            name: '数据录入时间',
            type: 'down',
            num: '20%'
          },
          {
            name: '政工作管理成本',
            type: 'down',
            num: '5%'
          },
        ],


        [
          {
            name: '培训成本',
            type: 'down',
            num: '5%'
          },
          {
            name: '工资成本',
            type: 'down',
            num: '--'
          },
        ],
      ],

      Index: 0,
    }
  },
  methods: {

  },
  created() {

  },

}
</script>

<style scoped lang="scss">
.workReport {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-image: url("@/assets/image/04.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 5%;
      padding-bottom: 2%;
      font-size: 24px;
      line-height: 33px;
      color: rgba(255, 255, 255, 1);
    }
  }
  .bottom {
    width: 92%;
    height: 80%;
    margin-top: 3%;
    margin-left: 2%;
    display: flex;
    align-items: center;
    justify-content: center;


    .row {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background-image: url("@/assets/image/14.png");
      background-position: center;
      background-size: 96% 100%;
      background-repeat: no-repeat;
      position: relative;


      .row-1 {
        width: 96%;
        height: 100%;
        margin-left: 2%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: absolute;
        z-index: 2;



        .row-2 {
          width: 49%;
          height: 48%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;


          .row-3 {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            line-height: 20px;
            margin-left: 15%;

            .item1 {
              width: 48%;
              font-size: 14px;
              color: #fff;
              text-align: left;
            }

            .item11 {
              width: 54%;
              font-size: 14px;
              color: #fff;
              text-align: left;
            }

            .item2 {
              width: 10%;
            }

            .item3 {
              width: 25%;
              text-align: left;
              font-size: 14px;
              font-weight: 700;
              color: rgba(66,217,240,1);
            }
          }
        }
      }


      .row2 {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;

        .row2-1 {
          width: 100px;
          height: 100px;
          background-image: url("@/assets/image/15.png");
          background-position: center;
          background-size: 96% 100%;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          animation: rotate 5s linear infinite;

          .row2-2 {
            font-size: 14px;
            color: #fff;
            animation: rotate 5s linear infinite;
            animation-direction: reverse;
          }
        }
      }

    }
  }

  @keyframes rotate {
    0% {
      transform: rotateZ(0);
    }

    100% {
      transform: rotateZ(360deg);
    }
  }
}
</style>

<template>
  <div class="dataBoardIndex">
    <div class="top">
      <div class="top_1">
        <div class="title">
          咖啡产业数字化服务大数据
        </div>
      </div>
    </div>
    <div class="bottom_content">
      <div class="bottom">
        <div class="left">
          <div class="left_1 borderBg">
            <left-there/>
          </div>
          <div class="left_2 borderBg">
            <one/>
          </div>
          <div class="left_3 borderBg">
            <two/>
          </div>
        </div>
        <div class="center">
          <div class="center_2 borderBg">
            <centerOne/>
          </div>
          <div class="center_3 borderBg">
            <foot/>
          </div>
        </div>
        <div class="right">
          <div class="right_1 borderBg">
            <rightOne/>
          </div>
          <div class="right_2 borderBg">
            <rightTwo/>
          </div>
          <div class="right_3 borderBg">
            <right-there/>
          </div>

        </div>
      </div>

<!--      <div class="foot">
        <foot/>
      </div>-->
    </div>

    <div class="up">
      <div class="btn" @click="open">产业</div>
    </div>
  </div>
</template>

<script>
import one from "@/views/data-board/left/one.vue";
// import one from "@/views/left/there.vue";
import two from "@/views/data-board/left/two.vue";
import centerOne from "@/views/data-board/center/one.vue";
import rightTwo from "@/views/data-board/right/two.vue";
import rightOne from "@/views/data-board/right/one.vue";
import foot from "@/views/data-board/foot.vue";
import RightThere from "@/views/data-board/right/there.vue";
import LeftThere from "@/views/data-board/left/there.vue";

export default {
  name: "dataBoardIndex",
  components: {
    LeftThere,
    RightThere,
    rightOne,
    rightTwo,
    centerOne,two, one, foot},
  methods: {
    open() {
      window.location.href = 'https://kcdp2.bsmyit.com/'
      // window.location.href = 'http://39.101.177.194:32051/#/'
    },
  },
}
</script>

<style scoped lang="scss">

  .up {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 0;
    top: 45%;
    z-index: 9999;

    .btn {
      width: 100%;
      height: 100%;
      border-radius: 50% 0 0 50%;
      //background-color: rgba(194,108,21,0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 16px;
      font-weight: 600;
      background: linear-gradient(to right, rgba(194,108,21,0.8), rgba(194,108,21,0.5), rgba(194,108,21,0.1));
    }
  }

  .dataBoardIndex {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .top {
      width: 100%;
      height: 10%;
      background: rgb(0,27,40);

      .top_1 {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("@/assets/image/topBg.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
      .title {
        font-size: 2rem;
        font-weight: bold;
        color: rgb(255,255,255);


      }
    }
    .bottom_content {
      width: 100%;
      height: 90%;
      background-image: url("@/assets/image/bottomBg.png");
      //background-image: url("@/assets/image/bg/104.gif");   // 103  104
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .bottom {
      //height: 75%;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;


      /*.borderBg {
        border: 1px solid rgba(2, 166, 181, 0.55);
        background: linear-gradient(to left, #02a6b5, #02a6b5) left top no-repeat,
        linear-gradient(to bottom, #02a6b5, #02a6b5) left top no-repeat,
        linear-gradient(to left, #02a6b5, #02a6b5) right top no-repeat,
        linear-gradient(to bottom, #02a6b5, #02a6b5) right top no-repeat,
        linear-gradient(to left, #02a6b5, #02a6b5) left bottom no-repeat,
        linear-gradient(to bottom, #02a6b5, #02a6b5) left bottom no-repeat,
        linear-gradient(to left, #02a6b5, #02a6b5) right bottom no-repeat,
        linear-gradient(to left, #02a6b5, #02a6b5) right bottom no-repeat;
        background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
      }*/

      .left {
        width: 30%;
        height: 100%;
        //flex: 1;
        display: flex;
        flex-direction: column;
        margin-left: 1%;
        margin-right: 1%;


        .left_1 {
          //flex: 1;
          width: 100%;
          height: 40%;
          margin-top: 2%;
          position: relative;

          .left_1_1 {
            position: absolute;
            height: 20%;
            border: 1px solid rgba(2, 166, 181, 0.55);
          }
        }
        .left_2 {
          //flex: 1;
          width: 100%;
          height: 35%;
          margin-top: 1%;
        }
        .left_3 {
          //flex: 1;
          width: 100%;
          height: 25%;
          margin-top: 1%;
        }
      }

      .center {
        width: 40%;
        height: 100%;
        //flex: 1;
        display: flex;
        flex-direction: column;


        .center_2 {
          width: 100%;
          height: 70.5%;
          //flex: 2;
          margin-top: 2%;
        }
        .center_3 {
          width: 100%;
          height: 25%;
          //flex: 2;
          margin-top: 3%;
        }

      }

      .right {
        width: 30%;
        height: 100%;
        //flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 1%;
        margin-left: 1%;

        .right_1 {
          //flex: 1;
          width: 100%;
          height: 30%;
          margin-top: 2%;
        }
        .right_2 {
          //flex: 1;
          width: 100%;
          height: 42%;
          margin-top: 2%;
        }
        .right_3 {
          //flex: 1;
          width: 100%;
          height: 24%;
          margin-top: 2%;
        }
      }
    }

    .foot {
      width: 98%;
      height: 25%;
      margin: 0 1%;
    }
  }
</style>

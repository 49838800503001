<template>
  <div class="rejectsAnalyse">
    <div class="top">
      <div class="title">
        服务扩展
      </div>
    </div>
    <div class="bottom">
      <div id="rejectPie" style="width: 100%;height: 100%"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rejectsAnalyse",
  data() {
    return {
      option: {
        color: ['rgba(90, 216, 166, 1)','rgba(246, 189, 22, 1)','rgba(232, 104, 74, 1)','rgba(92, 94, 247, 1)'],
        title: {
          text: '中小企业总数',
          subtext: '380',
          left: 'center',
          top: 'center',
          textStyle: {
            color: '#fff',
            fontSize: 12,
          },
          subtextStyle: {
            color: '#fff',
            fontSize: 20,
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c}家 ({d}%)' //自定义显示格式(b:name, c:value, d:百分比)
        },
        legend: {
          icon:'circle',
          left: 'center',
          bottom: '2%',
          itemHeight: 6,
          itemWidth: 6,
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '55%'],
            label:{
              color: '#fff',
              formatter: '{b}{c}家',
            },
            data: [],

          }
        ]
      },
      echartsPie: '',
      currentIndex: -1,
    }
  },
  methods: {
    getPie() {
      let data = [
        { value: 8, name: '专精特新企业' },
        { value: 45, name: '制造业' },
        { value: 281, name: '服务业' },
        { value: 46, name: '其他企业' }
      ];
      let arr = [];
      data.forEach(item => {
        let obj = {
          name: item.name,
          value: item.value,
        };
        arr.push(obj)
      })
      this.option.series[0].data = [...arr]


    },
    getEcharts(){
      this.$nextTick(() => {
       this.echartsPie = this.echarts.init(document.getElementById('rejectPie'));
        this.echartsPie.setOption(this.option);

        // 图表自适应
        window.addEventListener('resize', () => {
          setTimeout(() => {
            this.echartsPie.resize();
          },100)
        })
      })
    },

    // 饼状图实现循环高亮
    getInterval() {
      let dataLen = this.option.series[0].data.length;
      // 取消之前高亮的图形
      this.echartsPie.dispatchAction({
        type: 'downplay',
        seriesIndex: 0,
        dataIndex: this.currentIndex,
      });
      this.currentIndex = (this.currentIndex + 1) % dataLen;
      // 高亮当前图形
      this.echartsPie.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: this.currentIndex,
      });
      // 显示 tooltip
      this.echartsPie.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: this.currentIndex,
      });
    },
  },
  mounted() {
    this.getPie()
    this.getEcharts()
    setInterval(this.getInterval, 1500);
  },
}
</script>

<style scoped lang="scss">
.rejectsAnalyse {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-image: url("@/assets/image/04.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 5%;
      padding-bottom: 2%;
      font-size: 24px;
      line-height: 33px;
      color: rgba(255, 255, 255, 1);

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 85%;
  }
}
</style>

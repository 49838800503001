import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import ElementUI from 'element-ui';  //全局引入element
import 'element-ui/lib/theme-chalk/index.css'; // 引入样式
import scroll from 'vue-seamless-scroll';
import * as echarts from 'echarts';
// 导入 axios
import axios from 'axios'
// 全局配置 axios 请求的根路径, 可以不写.
axios.defaults.baseURL = 'http://localhost:8080'
// 把 axios 挂载到 Vue 原型上. 今后,在每个 .vue 组件中发起请求,直接调用 this.$http 即可.
Vue.prototype.$http = axios

Vue.prototype.echarts = echarts



Vue.config.productionTip = false

Vue.use(ElementUI);     //全局注入element
Vue.use(scroll);
Vue.use(echarts);

new Vue({
  render: h => h(App),
  router:router,
}).$mount('#app')

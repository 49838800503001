import { render, staticRenderFns } from "./there.vue?vue&type=template&id=1b8b3b5f&scoped=true&"
import script from "./there.vue?vue&type=script&lang=js&"
export * from "./there.vue?vue&type=script&lang=js&"
import style0 from "./there.vue?vue&type=style&index=0&id=1b8b3b5f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b8b3b5f",
  null
  
)

export default component.exports
<template>
  <div class="workReport">
    <div class="top">
      <div class="title">
        服务现场
      </div>
    </div>
    <div class="bottom">
      <div class="row">
        <div class="row-1">
          <div class="row1-1">
            <el-carousel indicator-position="none" height="9vh">
              <el-carousel-item v-for="(item,index) in list.one" :key="index">
                <el-image :src="item" style="width: 100%; height: 100%" fit="cover"/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="row1-2">
            <div>保山中咖食品有限公司</div>
          </div>
        </div>
        <div class="row-1">
          <div class="row1-1">
            <el-carousel indicator-position="none" height="9vh">
              <el-carousel-item v-for="(item,index) in list.two" :key="index">
                <el-image :src="item" style="width: 100%; height: 100%" fit="cover"/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="row1-2">
            <div>保山市高老庄农副产品开发有限公司</div>
          </div>
        </div>
        <div class="row-1">
          <div class="row1-1">
            <el-carousel indicator-position="none" height="9vh">
              <el-carousel-item v-for="(item,index) in list.there" :key="index">
                <el-image :src="item" style="width: 100%; height: 100%" fit="cover"/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="row1-2">
            <div>保山禾木咖啡有限责任公司</div>
          </div>
        </div>
        <div class="row-1">
          <div class="row1-1">
            <el-carousel indicator-position="none" height="9vh">
              <el-carousel-item v-for="(item,index) in list.four" :key="index">
                <el-image :src="item" style="width: 100%; height: 100%" fit="cover"/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="row1-2">
            <div>保山市隆阳区果馨麝香猫咖啡厂</div>
          </div>
        </div>
        <div class="row-1">
          <div class="row1-1">
            <el-carousel indicator-position="none" height="9vh">
              <el-carousel-item v-for="(item,index) in list.five" :key="index">
                <el-image :src="item" style="width: 100%; height: 100%" fit="cover"/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="row1-2">
            <div>云南咖蒙实业有限公司</div>
          </div>
        </div>
        <div class="row-1">
          <div class="row1-1">
            <el-carousel indicator-position="none" height="9vh">
              <el-carousel-item v-for="(item,index) in list.six" :key="index">
                <el-image :src="item" style="width: 100%; height: 100%" fit="cover"/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="row1-2">
            <div>保山锦庆热作科技有限公司</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "workReport",
  data() {
    return {
      list: {
        one: [
            'https://hczc.oss-cn-beijing.aliyuncs.com/tupian/mes/dp/%E6%9C%8D%E5%8A%A1%E5%9B%BE%E7%89%871.png',
            'https://hczc.oss-cn-beijing.aliyuncs.com/tupian/mes/dp/%E6%9C%8D%E5%8A%A1%E5%9B%BE%E7%89%872.png'
        ],
        two: [
            'https://hczc.oss-cn-beijing.aliyuncs.com/tupian/mes/dp/%E6%9C%8D%E5%8A%A1%E5%9B%BE%E7%89%873.png',
            'https://hczc.oss-cn-beijing.aliyuncs.com/tupian/mes/dp/%E6%9C%8D%E5%8A%A1%E5%9B%BE%E7%89%874.png',
        ],
        there: [
            'https://hczc.oss-cn-beijing.aliyuncs.com/tupian/mes/dp/%E6%9C%8D%E5%8A%A1%E5%9B%BE%E7%89%875.png',
            'https://hczc.oss-cn-beijing.aliyuncs.com/tupian/mes/dp/%E6%9C%8D%E5%8A%A1%E5%9B%BE%E7%89%876.png'
        ],
        four: [
            'http://zsjy.bsmyit.com/api/profile/test/38c4026de7014bd9830961c5dacc044c.jpeg',
            require('@/assets/image/guoxing02.jpg')
        ],
        five: [
            require('@/assets/image/kameng01.png')
        ],
        six: [
          require('@/assets/image/jinqing01.jpeg'),
          require('@/assets/image/jinqing02.jpeg')
        ],
      }
    }
  },
  methods: {

  },
  created() {},

}
</script>

<style scoped lang="scss">
.workReport {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-image: url("@/assets/image/04.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 5%;
      padding-bottom: 2%;
      font-size: 24px;
      line-height: 33px;
      color: rgba(255, 255, 255, 1);
    }
  }
  .bottom {
    width: 88%;
    height: 80%;
    margin-top: 2%;
    margin-left: 2%;
    padding: 2% 2% 0;
    background: rgba(255, 255, 255, 0.2);

    .row {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .row-1 {
        width: 48%;
        height: 9vh;
        position: relative;

        .row1-1 {
          width: 100%;
          position: absolute;
          z-index: 1;
        }
        .row1-2 {
          width: 100%;
          position: absolute;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(59, 55, 55, 0.6);
          color: #fff;
          font-size: 0.5rem;
          line-height: 30px;
          bottom: 0;
          //margin-top: 31%;
        }

        .item {
          width: 100%;
          height: 100%;
          position: relative;

          .img {
            position: absolute;
            z-index: 1;
          }

          .text {
            position: absolute;
            z-index: 2;
            color: #42b983;
          }
        }
      }
    }
  }
}
</style>

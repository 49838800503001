<template>
  <div class="rejectsAnalyse">
    <div class="top">
      <div class="title">
        模块活跃度
      </div>
    </div>
    <div class="bottom">
      <div id="echartsRadar" style="width: 100%;height: 100%;"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rightThere",
  data() {
    return {
      option: {
        color: ['rgba(134,190,241,1)','rgba(246, 189, 22, 1)','rgba(232, 104, 74, 1)','rgba(92, 94, 247, 1)'],
        legend: {
          show: false,
          icon:'circle',
          left: '0',
          top: '2%',
          itemHeight: 6,
          itemWidth: 6,
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
        },
        // tooltip: {},
        radar: [
          {
            //雷达图半径
            radius: '60%',
            center: ['50%', '60%'],
            indicator: [
              { name: '咖宝云供应链交易平台', max: 100 },
              { name: '数智工厂', max: 100 },
              { name: '数字农业', max: 100 },
              { name: '数字化经营管理', max: 100 },
              { name: '人才培养', max: 100 },
            ],
            axisName: {}
          }
        ],
        series: [
          {
            type: 'radar',
            // symbol: 'none',//去掉拐点的圈
            symbolSize : 5,
            /*label: {
              show: true, // 显示数值
              color: '#ffffff', //数值的颜色
              formatter:function(params) {
                return params.value+"%";
              },
            },*/
            areaStyle: {
              normal: {
                // color: '#0bdff3'
                color: 'rgba(134,190,241,0.6)' // 图表中各个图区域的颜色
              }
            },
            data: [
              {
                value: [95, 60, 30, 80, 85],
                name: '模块活跃度',
              },
            ],

          }
        ]

      },
    }
  },
  methods: {
    getEcharts(){
      this.$nextTick(() => {
        this.echartsPie = this.echarts.init(document.getElementById('echartsRadar'));

        var i = -2;
        var arr = this.option.series[0].data[0].value
        i++;
        console.log(arr[i],'====')
        this.option.radar[0].axisName = {
          color: '#fff',
          fontSize: 16,
          rich: {
            a: {
              color: '#fff',
              lineHeight: 20
            },
            b: {
              color: '#0bdff3',
            }
          },
          formatter: (a)=>{
            i++;
            return `${a}：${arr[i]}%`
          }

        }

        this.echartsPie.setOption(this.option);

        // 图表自适应
        window.addEventListener('resize', () => {
          setTimeout(() => {
            this.echartsPie.resize();
          },100)
        })
      })
    },


  },
  mounted() {
    this.getEcharts()
  },
}
</script>

<style scoped lang="scss">
.rejectsAnalyse {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-image: url("@/assets/image/04.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 5%;
      padding-bottom: 2%;
      font-size: 24px;
      line-height: 33px;
      color: rgba(255, 255, 255, 1);

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 1px solid rgba(2, 166, 181, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5%;

        .circle_1 {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: rgba(2, 166, 181, 1);
          position: absolute;
        }
      }
    }
  }

  .bottom {
    width: 100%;
    height: 75%;
    margin-top: 2%;
  }
}
</style>

<template>
<div class="processProgress">
  <div class="top">
    <div class="title">
      服务成效
    </div>
  </div>
  <div class="content">
    <div class="content_sco">
      <div class="one">
        <div class="one_1">
          <div class="row1">
            <div class="item1">初期平均等级</div>
            <div class="item2">L1</div>
            <div class="item3">26.68</div>
          </div>
          <div class="row1-2">
<!--            <div class="">
              <img src="@/assets/image/09.png" style="width: 35px;border: 1px solid red">
            </div>-->
          </div>
          <div class="row1">
            <div class="item1">当前平均等级</div>
            <div class="item2">L2</div>
            <div class="item3">48.59</div>
          </div>
        </div>
        <div class="one_2">
          <div class="row2 row3">
            <div class="row2-1">
              <div class="item2-1">数字化基础</div>
              <div class="item2-2">
                <div class="item22-1">期初值：<span class="span">11.20</span></div>
                <div class="item22-1">当前值：<span class="span">13.07</span></div>
              </div>
            </div>

            <div class="row2-1">
              <div class="item2-1">数字化管理</div>
              <div class="item2-2">
                <div class="item22-1">期初值：<span class="span">4.32</span></div>
                <div class="item22-1">当前值：<span class="span">8.44</span></div>
              </div>
            </div>
          </div>
          <div class="row2 row4">
            <div class="row2-1">
              <div class="item2-1">数字化经营</div>
              <div class="item2-2">
                <div class="item22-1">期初值：<span class="span">11.03</span></div>
                <div class="item22-1">当前值：<span class="span">21.55</span></div>
              </div>
            </div>
            <div class="row2-1">
              <div class="item2-1">数字化成效</div>
              <div class="item2-2">
                <div class="item22-1">期初值：<span class="span">4.51</span></div>
                <div class="item22-1">当前值：<span class="span">5.22</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="two">
<!--        <div class="title2">
          服务扩展
        </div>-->
        <div id="centerOneEcharts" style="width: 100%;height: 100%;"></div>
      </div>
    </div>

  </div>
</div>
</template>

<script>
export default {
  name: "centerOne",
  data() {
    return {
      option: {
        tooltip: {},
        legend: {
          bottom: '0%',
          icon: 'circle',
          itemHeight: 6,
          itemWidth: 6,
          textStyle: {
            color: "rgba(161, 200, 255, 1)",
            fontSize: 10,
          }
        },
        dataset: {
          dimensions: ['product', '期初', '当前'],
          source: [
            { product: '设备系统', '期初': 6.53, '当前': 6.82 },
            { product: '数据资源', '期初': 3.09, '当前': 4.16 },
            { product: '网络安全', '期初': 1.65, '当前': 2.10 },
            { product: '研发设计', '期初': 1.41 , '当前': 1.70 },
            { product: '生产管控', '期初': 1.62 , '当前': 8.00 },
            { product: '采购供应', '期初': 0.64 , '当前': 0.85 },
            { product: '营销管理', '期初': 2.12 , '当前': 3.00 },
            { product: '产品服务', '期初': 2.92 , '当前': 4.24 },
            { product: '业务协同', '期初': 2.33 , '当前': 3.76 },
            { product: '经营战略', '期初': 0.24 , '当前': 1.88 },
            { product: '管理机制', '期初': 1.13 , '当前': 2.57 },
            { product: '人才建设', '期初': 1.84 , '当前': 2.87 },
            { product: '资金投入', '期初': 1.12 , '当前': 1.12 },
            { product: '价值效益', '期初': 1.32 , '当前': 1.72 },
            { product: '生产效益', '期初': 1.76 , '当前': 1.88 },
            { product: '产品质量', '期初': 1.43 , '当前': 1.62 },
          ]
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            textStyle: {
              color: 'rgba(161, 200, 255, 1)',
              fontSize: 8,
            },
          },
        },
        yAxis: {
          axisLabel: {
            textStyle: {
              color: 'rgba(161, 200, 255, 1)',
              fontSize: 8,
            },
          },
          "splitLine": {
            "show": false
          },
        },
        grid: {
          left: '2%',
          right: '2%',
          bottom: '15%',
          top: '10%',
          containLabel:true,
        },
        series: [
          { type: 'bar',
            barWidth: 8,
            label: {
              // show: true,
              position: 'top',
              color: '#fff',
            },
            itemStyle: {
              normal: {
                borderRadius: 8, // 设置单个数值
                color: {
                  type: 'linear',
                  x: 0, //右
                  y: 0, //下
                  x2: 0, //左
                  y2: 1, //上
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(31, 117, 255, 1)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(136, 128, 255, 1)' // 100% 处的颜色
                    }
                  ]
                }
              }
            }

          },
          { type: 'bar',
            barWidth: 8,
            label: {
              // show: true,
              position: 'top',
              color: '#fff',
            },
            itemStyle: {
              normal: {
                borderRadius: 8, // 设置单个数值
                color: {
                  type: 'linear',
                  x: 0, //右
                  y: 0, //下
                  x2: 0, //左
                  y2: 1, //上
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(255, 193, 87, 1)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(191, 89, 255, 1)' // 100% 处的颜色
                    }
                  ]
                }
              }
            }

          }
        ],

      },
      colorList: [
        ['rgba(31, 117, 255, 1)', 'rgba(136, 128, 255, 1)'],
        ['rgba(255, 193, 87, 1)', 'rgba(191, 89, 255, 1)'],
      ],
    }
  },

  methods: {
    getEcharts(){
      this.$nextTick(() => {
        let myChart = this.echarts.init(document.getElementById('centerOneEcharts'));

        myChart.setOption(this.option);
        // 图表自适应
        window.addEventListener('resize', () => {
          setTimeout(() => {
            myChart.resize();
          },100)
        })
      })
    },

  },

  mounted() {
    this.getEcharts()
  },

}
</script>

<style scoped lang="scss">
.processProgress {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .top {
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      width: 25%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      background-image: url("@/assets/image/07.png");
      background-size: 100% 70%;
      background-repeat: no-repeat;
      background-position: bottom;
      font-size: 24px;
      line-height: 33px;
      color: rgba(255, 255, 255, 1);


    }
  }
  .content {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .content_sco {
      width: 100%;
      height: 100%;
      background-image: url("@/assets/image/10.png");
      background-size: 50% 50%;
      background-position: center;
      background-repeat: no-repeat;


      .one {
        width: 100%;
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .one_1 {
          width: 50%;
          height: 40%;
          //margin-top: 5%;
          display: flex;
          justify-content: space-between;
          position: absolute;
          z-index: 1;

          .row1 {
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .item1 {
              width: 100%;
              color: rgba(161, 200, 255, 1);
              font-size: 18px;
            }
            .item2 {
              width: 35%;
              height: 40px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: rgba(255, 87, 51, 1);
              font-size: 18px;
              font-weight: 600;
              background-image: url("@/assets/image/08.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
            }
            .item3 {
              width: 100%;
              color: rgba(255, 255, 255, 1);
              font-size: 20px;
            }
          }

          .row1-2 {
            width: 40%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-image: url("@/assets/image/16.png");
            background-repeat: no-repeat;
            background-size: 35% 35%;
            background-position: center;
          }

        }

        .one_2 {
          width: 100%;
          height: 60%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-top: 10%;


          .row2 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .row2-1 {
              width: 25%;
              height: 100%;
              background-image: url("@/assets/image/03.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;


              .item2-1 {
                width: 90%;
                height: 20%;
                line-height: 18px;
                font-size: 14px;
                color: rgba(255, 255, 255, 1);
              }

              .item2-2 {
                height: 70%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .item22-1 {
                  width: 100%;
                  font-size: 14px;
                  color: rgba(255, 255, 255, 1);
                  line-height: 20px;

                  .span {
                    font-size: 18px;
                    font-weight: 700;
                  }
                }
              }
            }

            .row2-2 {
              width: 25%;
              height: 100%;
            }
          }

          .row3 {
            width: 100%;
            height: 40%;
          }
          .row4 {
            width: 90%;
            height: 40%;
          }
        }
      }
      .two {
        width: 100%;
        height: 40%;

        .title2 {
          width: 80%;
          height: 20%;
          display: flex;
          align-items: center;
          background-image: url("@/assets/image/04.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          padding-left: 5%;
          padding-bottom: 1%;
          font-size: 24px;
          line-height: 33px;
          color: rgba(255, 255, 255, 1);
        }
      }

    }


  }
  @keyframes run {
    0% {
      top: 0;
    }
    100% {
      top: -500px;
    }
  }
  @keyframes rowup {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(0, -200px, 0);
      transform: translate3d(0, -200px, 0);
    }
  }
}
</style>

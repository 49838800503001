<template>
  <div class="faultReporting">
    <div class="top">
      <div class="title">
        服务企业一览表
      </div>
    </div>
    <div class="bottom">
      <div class="bottom_1">
        <div class="bottom_title">
          <div class="title_item">示范企业名称</div>
<!--          <div class="title_item title_item2">开始服务时间</div>-->
          <div class="title_item title_item2">服务类型</div>
          <div class="title_item title_item3">服务内容</div>
<!--          <div class="title_item title_item2">期初评分</div>
          <div class="title_item title_item2">等级</div>
          <div class="title_item title_item2">当前评分</div>
          <div class="title_item title_item2">等级</div>-->
          <div class="title_item title_item4">客户满意度</div>
        </div>
        <vue-seamless-scroll :data="listData" class="warp" :class-option="defaultOption">
          <ul class="item">
            <li v-for="(item, index) in listData" :key="index" :class="index % 2 === 1?'styColor':''">
              <span class="span">{{item.type1}}</span>
<!--              <span class="span2">{{item.two}}</span>-->
<!--              <span class="">{{item.there}}</span>-->
              <span class="span2">{{item.four}}</span>
              <span class="span3">{{item.five}}</span>
<!--              <span class="span2">{{item.six}}</span>
              <span class="span2">{{item.seven}}</span>
              <span class="span2">{{item.eight}}</span>
              <span class="span2">{{item.nine}}</span>-->
              <span class="span4">
                <el-rate
                    v-model="item.ten"
                    disabled>
                </el-rate>
              </span>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dataFoot",
  computed: {
    defaultOption () {
      return {
        step: 0.15, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  data() {
    return {
      listData: [
        {
          "type1": "保山中咖食品有限公司",
          "two": "2022年12月",
          "there": "已上线",
          "four": "综合型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理、云种植",
          "six": "47.31",
          "seven": "L2",
          "eight": "71.75",
          "nine": "L3",
          "ten": "5"
        },
        {
          "type1": "保山市高老庄农副产品开发有限公司",
          "two": "2022年12月",
          "there": "已上线",
          "four": "综合型",
          "five": "数智工厂、云种植、咖啡交易中心、人才培养、经营管理",
          "six": "37.06",
          "seven": "L1",
          "eight": "62.44",
          "nine": "L3",
          "ten": "5"
        },
        {
          "type1": "云南咖蒙实业有限公司",
          "two": "2023年5月",
          "there": "已上线",
          "four": "生产型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理",
          "six": "37.87",
          "seven": "L1",
          "eight": "61.06",
          "nine": "L3",
          "ten": "5"
        },
        {
          "type1": "云南景兰热作科技有限公司",
          "two": "2022年12月",
          "there": "已上线",
          "four": "生产型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理",
          "six": "38.37",
          "seven": "L1",
          "eight": "60.45",
          "nine": "L3",
          "ten": "5"
        },
        {
          "type1": "一树咖啡保山有限公司",
          "two": "2023年5月",
          "there": "已上线",
          "four": "综合型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理、云种植",
          "six": "36.61",
          "seven": "L1",
          "eight": "60.19",
          "nine": "L3",
          "ten": "5"
        },
        {
          "type1": "保山禾木咖啡有限责任公司",
          "two": "2023年4月",
          "there": "已上线",
          "four": "综合型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理、云种植",
          "six": "13.56",
          "seven": "L0",
          "eight": "42.64",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山市新寨咖啡有限公司",
          "two": "2023年5月",
          "there": "已上线",
          "four": "综合型",
          "five": "数智工厂、云种植、咖啡交易中心、人才培养、经营管理",
          "six": "34.46",
          "seven": "L1",
          "eight": "42.29",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山鑫隆农业科技发展有限责任公司",
          "two": "2023年4月",
          "there": "已上线",
          "four": "综合型",
          "five": "咖啡交易中心、人才培养、经营管理、数智工厂、云种植",
          "six": "18.79",
          "seven": "L0",
          "eight": "41.61",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山云牛农业科技有限公司",
          "two": "2023年6月",
          "there": "已上线",
          "four": "农业型",
          "five": "云种植、咖啡交易中心、经营管理",
          "six": "13.19",
          "seven": "L0",
          "eight": "41.19",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山市潞山云数农业开发有限公司",
          "two": "2023年4月",
          "there": "已上线",
          "four": "农业型",
          "five": "云种植、咖啡交易中心、人才培养、经营管理",
          "six": "12.55",
          "seven": "L0",
          "eight": "40.94",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山高黎之心农业开发有限公司",
          "two": "2023年4月",
          "there": "已上线",
          "four": "综合型",
          "five": "咖啡交易中心、人才培养、经营管理、云种植、数智工厂",
          "six": "19.64",
          "seven": "L0",
          "eight": "40.94",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山尚品农业发展有限公司",
          "two": "2023年6月",
          "there": "已上线",
          "four": "生产型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理",
          "six": "22.08",
          "seven": "L1",
          "eight": "40.49",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山蕊溪农业开发有限公司",
          "two": "2023年4月",
          "there": "已上线",
          "four": "生产型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理",
          "six": "27.41",
          "seven": "L1",
          "eight": "40.38",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山市喝客咖啡有限公司",
          "two": "2023年6月",
          "there": "已上线",
          "four": "生产型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理",
          "six": "40.12",
          "seven": "L2",
          "eight": "50.37",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山锦庆热作科技有限公司",
          "two": "2023年8月",
          "there": "已上线",
          "four": "生产型",
          "five": "咖啡交易中心、人才培养、经营管理",
          "six": "33.73",
          "seven": "L1",
          "eight": "49.99",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山市云潞咖啡产业开发有限责任公司",
          "two": "2023年8月",
          "there": "已上线",
          "four": "生产型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理",
          "six": "21.95",
          "seven": "L1",
          "eight": "49.21",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山市隆阳区果馨麝香猫咖啡厂",
          "two": "2023年8月",
          "there": "已上线",
          "four": "生产型",
          "five": "云种植、咖啡交易中心、人才培养、经营管理",
          "six": "13.29",
          "seven": "L0",
          "eight": "43.31",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "腾冲市走夷方文化传播有限公司",
          "two": "2023年8月",
          "there": "已上线",
          "four": "综合型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理",
          "six": "20.69",
          "seven": "L1",
          "eight": "42.82",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山新咖农旅发展有限责任公司",
          "two": "2023年9月",
          "there": "已上线",
          "four": "综合型",
          "five": "数智工厂、云种植、咖啡交易中心、人才培养、经营管理",
          "six": "28.69",
          "seven": "L1",
          "eight": "45.20",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "云南云凯清扬咖啡有限责任公司",
          "two": "2023年9月",
          "there": "已上线",
          "four": "生产型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理",
          "six": "28.78",
          "seven": "L1",
          "eight": "52.23",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "保山市隆阳区金潞农副产品有限公司",
          "two": "2023年4月\t",
          "there": "已上线",
          "four": "综合型",
          "five": "数智工厂、咖啡交易中心、人才培养、经营管理、云种植",
          "six": "22.43",
          "seven": "L1",
          "eight": "43.43",
          "nine": "L2",
          "ten": "5"
        },
        {
          "type1": "云南立春冻干食品有限公司",
          "two": "2023年9月",
          "there": "综合型",
          "four": "综合型",
          "five": "数智工厂、咖啡交易中心、云种植、人才培养、经营管理",
          "six": "17.39",
          "seven": "L1",
          "eight": "46.01",
          "nine": "L2",
          "ten": "5"
        }
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.faultReporting {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 15%;

    .title {
      width: 80%;
      height: 100%;
      display: flex;
      align-items: center;
      background-image: url("@/assets/image/04.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 5%;
      padding-bottom: 1.5%;
      font-size: 24px;
      line-height: 33px;
      color: rgba(255, 255, 255, 1);


    }
  }

  .bottom {
    width: 100%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bottom_1 {
      margin-top: 2%;
      width: 100%;
      height: 90%;

      .bottom_title {
        width: 100%;
        height: 20%;

        display: flex;
        justify-items: center;
        align-items: center;
        background-color: rgba(28,125,232, 1);


        .title_item {
          //flex: 1;
          width: 35%;
          color: #fff;
          font-size: 16px;
          font-weight: 700;
        }

        .title_item2 {
          width: 15%;
        }
        .title_item3 {
          width: 30%;
        }
        .title_item4 {
          width: 20%;
        }
      }

      .warp {
        height: 13vh;
        width: 100%;
        overflow: hidden;
        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;
          li{
            display: block;
            height: 30px;
            line-height: 30px;
            display: flex;
            justify-content: space-between;
            color: #fff;

            .span {
              //flex: 1;
              width: 35%;
              overflow:hidden;
              white-space: nowrap;
              text-overflow:ellipsis;
              font-size: 12px;

            }

            .span2 {
              width: 15%;
              font-size: 12px;
            }
            .span3 {
              width: 30%;
              overflow:hidden;
              white-space: nowrap;
              text-overflow:ellipsis;
              font-size: 12px;
            }
            .span4 {
              width: 20%;
            }

            span:hover{
              text-overflow:inherit;
              overflow: visible;
              white-space: nowrap;
            }
          }

          .styColor {
            background-color: rgba(42, 106, 201, 0.5);
          }
        }
      }
    }
  }
}
</style>

<template>
<div class="deviceInformation">
  <div class="top">
    <div class="title">
      企业类型
    </div>
  </div>
  <div class="bottom">
    <div class="bottom_1">
      <div class="one" v-for="(item,index) in list2" :key="index">
        <div class="one_1">
          <el-progress type="circle" :width="90" :stroke-width="12" text-color="#fff" :define-back-color="'rgba(255, 255, 255, 0.2)'"
                       :percentage="((item.num*1 / 22) * 100).toFixed(0)" :color="color[index]"></el-progress>
        </div>
        <div class="one_2">
          <div class="one2_1">
            <div class="one_21">{{ item.name }}</div>
            <div class="one_22">{{ item.num }}</div>
            <div class="one_23">户</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "deviceInformation",
  computed: {

  },
  data(){
    return {
      color: ['rgba(98, 255, 200, 1)','rgb(106,192,239)','rgba(247, 253, 134, 1)'],
      list2: [
        {
          name: '综合性',
          num: 11,
        },
        {
          name: '生产型',
          num: 8,
        },
        {
          name: '农业型',
          num: 3,
        },
      ],
    }
  },

  methods: {
    /*format(percentage) {
      return `${percentage}%占比`;
    }*/
  },
}
</script>

<style scoped lang="scss">
.deviceInformation {
  width: 100%;
  height: 100%;

  .top {
    width: 100%;
    height: 15%;
    margin-top: 2%;
    margin-left: 2%;

    .title {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-image: url("@/assets/image/04.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding-left: 5%;
      padding-bottom: 2%;
      font-size: 24px;
      line-height: 33px;
      color: rgba(255, 255, 255, 1);



    }
  }

  .bottom {
    width: 100%;
    height: 83%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 2%;

    .bottom_1 {
      width: 100%;
      height: 85%;
      margin-top: 2%;
      display: flex;
      flex-wrap: wrap;

      .one {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .one_1 {
          width: 100%;
          height: 70%;
        }

        .one_2 {
          width: 100%;
          height: 20%;
          display: flex;
          justify-content: center;
          align-items: center;

          .one2_1 {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
          }

          .one_21 {
            font-size: 18px;
            color: rgba(255, 255, 255, 1);

          }
          .one_22 {
            font-size: 20px;
            color: rgba(61, 230, 255, 1);
            font-weight: 700;
            margin-left: 5px;
          }
          .one_23 {
            font-size: 12px;
            color: rgba(61, 230, 255, 1);

          }
        }
      }
    }
  }
}
</style>
